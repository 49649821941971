.nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

.main-navigation {
    border: var(--bd-base);
    border-left: 0;
    border-right: 0;
    margin-top: var(--space-s);

    .container {
        display: flex;
    }

    span,
    a {
        padding: var(--space-xs) var(--space-m);
        display: block;
        text-transform: uppercase;
        font-weight: bold;

        &:hover,
        &.active {
            color: var(--c-green);
        }
    }

    @media screen and (min-width: 760px) {
        .sub-menu {
            position: absolute;
            opacity: 0;
            top: 99%;
            pointer-events: none;
        }
        .has-children:hover {
            .sub-menu {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    @media screen and (max-width: 760px) {
        position: fixed;
        display: none;
        z-index: 998;
        inset: 0;
        height: 100vh;
        top: -20px;
        background-color: #fff;
        overflow: scroll;

        .sub-menu {
            padding-left: var(--space-s);
        }

        .menu-open & {
            display: block;
        }
    }
}

.inline-contact {
    padding-right: 15px;

    strong {
        font-size: 1rem;
        font-weight: bold;
        padding-right: 5px;
    }
}

.footer-nav {
    a {
        font-size: 0.9rem;
    }
}

nav.pages {
    display: flex;
    justify-content: space-between;
    margin-top: var(--spacing) / 3;
    flex-wrap: wrap;
}

.site-footer nav {
    ul {
        margin: 0;
        margin-left: 1em;
        padding: 0;
        list-style: disc;
    }
}

.breadcrumb {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    font-size: var(--fz-small);

    li:not(:last-child) {
        &:after {
            content: ">";
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .active {
        font-weight: bold;
    }
}

.pagination {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    margin: var(--space-m);
    gap: var(--space-3xs);

    .page-link {
        padding: var(--space-2xs);
        border: var(--bd-base);
        border-radius: var(--bdrs-base);

        &:hover {
            color: var(--c-green);
        }
    }
    .mall-pagination--reviews & {
        justify-content: flex-start;
        margin-left: 0;
    }
}

.mall-my-account {
    display: flex;
    gap: var(--space-m);
    flex-wrap: wrap;
}

.mall-my-account__nav {
    @extend %box-base;
    // flex-grow: 1;
    min-width: 300px;
    max-width: 500px;
}

.mall-my-account__content {
    flex-grow: 1;
}

.mall-my-account__nav-item {
    @extend %link;
    margin-bottom: var(--space-2xs);

    &.mall-my-account__nav-item--active {
        color: var(--c-green);
    }
}

.mall-callout {
    border: 1px solid var(--c-green);
    background-color: var(--c-green);
    color: #fff;
    border-radius: 3px;
    text-align: center;
    margin: var(--space-xs) 0;
    padding: var(--space-m);
    font-weight: bold;
}

.mall-address-list {
    overflow: scroll;
}

.mall-address-list__list {
    margin-top: var(--space-m);
    margin-bottom: var(--space-m);
}

.mall-address-list__table {
    border-collapse: collapse;

    td {
        padding: var(--space-xs) var(--space-s);
        border-bottom: var(--bd-base);
        font-size: 0.9em;
    }

    tr:last-child td {
        border: none;
    }

    tr:nth-child(odd) {
        background-color: var(--bg-top);
    }

    a {
        text-transform: uppercase;
        &:hover {
            color: var(--c-green);
        }
    }
}

.mall-cart__table,
.mall-table,
.mall-orders-list__table {
    width: 100%;
    margin-top: var(--space-m);
    margin-bottom: var(--space-m);
    border-collapse: collapse;

    background-color: #fff;

    td,
    th {
        padding: var(--space-3xs) var(--space-xs);
    }

    thead {
        th {
            background-color: var(--bg-thead-th);
        }
    }
    tbody td {
        border-bottom: 1px solid var(--bdc-default, #ccc);
    }

    tfoot {
        td {
        }
    }

    .mall-cart__taxes td {
        font-size: 0.8rem;
    }

    .is-open td {
        border-bottom: 0;
        background-color: #fcfcfc;
    }
}
.mall-orders-list__details {
    background-color: #fcfcfc;
}
.mall-orders-list__table {
    .mall-order-details__row {
        margin: var(--space-m) 0;
    }

    .mall-order-details__detail {
        display: flex;
        border-bottom: 1px solid var(--bdc-default, #ccc);
        padding: var(--space-3xs) 0;

        a {
            margin-left: var(--space-m);
        }
    }

    h5 {
        margin: 0;
        font-size: 1rem;
        flex-basis: 20%;
    }
}

.mall-wishlist-button {
    position: relative;
    margin-top: var(--space-xs);
}

.mall-wishlist-button__popup,
.mall-modal {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    background-color: #fff;
    z-index: 1;
    border: var(--bd-base);
    padding: var(--space-xs);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    top: 100%;
}
.mall-modal {
    position: fixed;
    z-index: 99999;
    background: #fff;
    left: 50%;
    top: 50%;
    border-radius: 2px;
    box-shadow: 0 0 20px rgb(0 0 0 / 30%);
    padding: var(--space-xs);
    translate: -50% -50%;
    overflow: scroll;
    max-height: 70vh;
}

.mall-wishlist-button__popup--visible,
.mall-modal--visible {
    opacity: 1;
    pointer-events: all;
}

.mall-wishlist-item__name {
    font-weight: bold;
}

.mall-modal__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mall-product-service {
    margin-top: var(--space-s);
    margin-bottom: var(--space-s);
}

.mall-product-service-options {
    display: flex;
    flex-direction: column;
}

.mall-product-service__name {
    font-size: 1.2em;
    margin-bottom: 0;
}

.mall-product-service-option {
    margin: var(--space-3xs);
    display: flex;
    span {
        font-weight: normal;
    }
}
.mall-product-service-option__description,
.mall-product-service__description {
    p:first-child {
        margin-top: 0;
        font-size: 0.85em;
    }
    p:only-of-type {
        margin-bottom: 0;
    }
}

[type="submit"],
a.button,
.mall-add-to-cart-button,
.mall-btn {
    @extend %button-base;
}

a.button,
.mall-add-to-cart-button,
.mall-btn--primary,
.mall-btn-checkout {
    background: var(--bg-button);
    text-transform: uppercase;
    font-weight: bold;
    padding: var(--p-button, 1rem);
    color: var(--c-button);
    border: var(--bd-button);
    border-radius: var(--bdrs-button);
    letter-spacing: 0.04em;

    &:hover,
    &:active {
        box-shadow: 0 0 10px hsl(var(--c-green-hsl));
    }
}

.mall-btn--link,
.mall-btn--default,
.mall-modal__actions .mall-btn,
.mall-discount-applier .mall-btn,
.mall-cart-summary__action a,
.mall-address__actions a,
.js-mall-remove-product,
.mall-order-details__detail a {
    border-bottom: 2px solid var(--c-green);
}

.js-mall-remove-product {
    border-color: red;
}

.mall-btn--sm {
    padding: 5px 15px;
    text-transform: none;
}

.button-alt,
.mall-payment-method__select-button,
.mall-add-to-wishlist-button,
.mall-shipping-method__select-button {
    background-color: none;
    @extend %link;
}

// .mall-add-to-wishlist-button {
//     background: var(--bg-button-alt);
//     color: var(--c-button-alt);
//     border: var(--bd-button-alt);
// }

.menu-toggle {
    position: fixed;
    bottom: 10px;
    right: 20px;
    border-radius: 50%;
    background-color: var(--c-green);
    aspect-ratio: 1/1;
    border: none;
    font-weight: bold;
    color: #fff;
    box-shadow: var(--bxsh-base);
    z-index: 999;

    @media screen and (min-width: 760px) {
        display: none;
    }
}

.mall-form-control {
    margin-bottom: var(--space-s);

    label {
        display: block;
        margin-bottom: var(--space-3xs);
    }

    div[data-validate-for].visible {
        text-transform: uppercase;
        font-size: 0.7rem;
        margin-top: var(--space-3xs);
        letter-spacing: 0.03em;
        display: inline-block;
        color: red;
        border-bottom: 1px solid currentColor;
    }
}

label {
    font-weight: bold;
    color: var(--color-brand-primary);
    margin-right: 10px;

    &.legal {
        line-height: 1.5;
        text-transform: none;
        font-size: 0.58rem;
        font-weight: normal;
        display: inline-block;
        color: var(--brand-gray);

        a {
            font-size: inherit;
            font-weight: bold;
        }
    }
}

input {
    &[type="text"],
    &[type="password"],
    &[type="phone"],
    &[type="url"],
    &[type="date"],
    &[type="email"] {
        @extend %input;
    }
}
select {
    @extend %input;
}

textarea {
    @extend %input;
    width: 80%;
}

.fieldset.inline {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 54em;

    p + p {
        margin-left: 2rem;
    }
}

.mall-checkbox {
    input {
        @include beforeInput;
    }
}
.mall-product-service-option label {
    @include beforeInput;
}

input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    z-index: 0;
    transform: scale(0);
    display: block;

    & + label {
        @include beforeInput;
    }

    &:checked + label:before {
        background-color: var(--c-green);
    }
}

input[type="radio"] + label:before {
    border-radius: 50%;
}

input[type="file"] {
    width: 350px;
    max-width: 100%;
    color: #444;
    padding: 5px;
    background: #fff;

    border: 1px solid #555;
    border-radius: var(--bdrs-button);
}
::file-selector-button {
    margin-right: 20px;
    border: none;
    background: var(--bg-button);
    padding: var(--space-3xs);
    border-radius: var(--bdrs-button);
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
    background: #0d45a5;
}

.mall-form-control {
    select,
    input {
        max-width: 100%;
    }
}

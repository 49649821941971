main {
    margin: var(--space-l) 0;
    gap: var(--space-l);
}

.container {
    @extend %container;
}

.hero-image + main,
.hero-image + main .gray-bg {
    margin-top: 0;
}

[class*="grid-"] {
    display: grid;
}

.icons-list {
    background: var(--bg-icons-list);
    padding: var(--p-icon-list);
}

.section {
    margin-top: var(--space-l);
    margin-bottom: var(--space-l);
}

.icon-box {
    align-items: center;
    flex-basis: 25%;
    flex-grow: 1;
    gap: 10px;

    figure {
        flex-basis: 48px;
    }

    strong {
        display: block;
        text-transform: uppercase;
        font-weight: 800;
    }
}

.grid-sidebar {
    margin: var(--space-l) 0;
    display: grid;
    gap: var(--space-m);

    @media screen and (min-width: 760px) {
        grid-template-columns: 1fr 4fr;
    }

    .sidebar {
        @extend %box-base;
        padding: var(--space-m);
    }
}

.mall-cart-summary__info {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: var(--space-m);

    h4 {
        text-transform: uppercase;
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid var(--bdc-base);
        padding: var(--space-xs);
    }
}

.mall-cart-summary__method-logo {
    max-width: 100px;
}

.mall-cart__empty {
    @extend %box-base;
    margin: var(--space-m) auto;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.mall-products-item__short-description {
    font-size: 0.8em;
    margin: var(--space-s) 0;
}

.mall-products-item__old-price {
    text-decoration: line-through;
    font-size: 0.8em;
}

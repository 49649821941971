.headroom {
    will-change: transform;
    transition: transform 200ms linear;
    position: fixed;
}

.site-header {
    width: 100%;
    transition: all 0.7s ease-out;
    top: 0;
    z-index: 999;
    background-color: var(--bg-header);

    @media screen and (min-width: 760px) {
        &.headroom--pinned {
            transform: translateY(0%);
        }

        &.headroom--unpinned {
            transform: translateY(-200%);

            &.toggled {
                transform: translateY(0);
            }
        }

        &.headroom--top {
            box-shadow: none;
        }

        &.headroom--not-top {
            box-shadow: 0 0 20px hsl(0 0% 0% / 0.1);
        }
    }

    > .flex {
        flex-wrap: wrap;
        margin-top: var(--space-2xs);
        margin-bottom: var(--space-2xs);
    }

    .brand svg {
        max-width: 30vw;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        gap: var(--space-2xs);
    }

    .brand {
        max-width: 250px;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

.top {
    background: var(--bg-top);
    padding: 10px 0;
    font-size: var(--fz-small);

    a {
        @extend %link;
    }
    @media screen and (max-width: 760px) {
        display: none;
    }
}

.search-form {
    border: var(--bd-input);
    display: flex;
    align-items: center;
    border-radius: var(--bdrs-base);
    flex-grow: 1;

    form {
        display: flex;
        height: 100%;
    }

    input,
    button {
        border: none;
        flex-grow: 1;
        max-width: 45vw;
    }

    button {
        width: 50px;
    }
}

.w-24 {
    text-align: center;

    svg {
        width: 28px;
        min-width: 28px;
    }

    strong {
        display: block;
    }

    @media screen and (max-width: 760px) {
        strong {
            display: none;
        }
    }
}

/*  Structure */

$size__tablet: 796px;
$size__large-tablet: 960px;
$size__small-tablet: 680px;
$size__header-width: 1700px;
$size__mobile: 860px;
$size__tablet: 23em;

:root {
    --size-container: 80rem;
    --size-outer-container: 100em;
    --c-primary-hsl: 80, 5%, 12%;
    --c-green-hsl: 73, 73%, 47%;
    --c-green: hsl(var(--c-green-hsl));
    --scroll-padding: 100px;

    --space-3xs: clamp(0.31rem, calc(0.29rem + 0.1vw), 0.38rem);
    --space-2xs: clamp(0.56rem, calc(0.5rem + 0.29vw), 0.75rem);
    --space-xs: clamp(0.88rem, calc(0.8rem + 0.39vw), 1.13rem);
    --space-s: clamp(1.13rem, calc(1.01rem + 0.59vw), 1.5rem);
    --space-m: clamp(1.69rem, calc(1.51rem + 0.88vw), 2.25rem);
    --space-l: clamp(2.25rem, calc(2.01rem + 1.18vw), 3rem);
    --space-xl: clamp(3.38rem, calc(3.02rem + 1.76vw), 4.5rem);
    --space-2xl: clamp(4.5rem, calc(4.03rem + 2.35vw), 6rem);
    --space-3xl: clamp(6.75rem, calc(6.04rem + 3.53vw), 9rem);

    --c-primary: hsl(var(--c-primary-hsl));
    --c-body: var(--c-primary);
    --f-body: Roboto;
    --f-headings: Roboto;

    --bg-header: #fff;
    --p-header: var(--space-m);

    --bg-top: #fafafa;
    --bg-hero: hsl(90, 50%, 91%);
    --fz-body: 16px;
    --bg-scrollbar: var(--c-green);
    --c-selection: #fff;
    --bg-selection: var(--c-primary);

    --fill-icon: hsl(var(--c-primary-hsl));
    --stroke-icon: hsl(var(--c-primary-hsl));
    --fill-accent: var(--c-green);
    --bg-footer: var(--c-primary);
    --c-footer: #fff;
    --bdc-base: #d7d7d7;

    --bd-input: 1px solid #d7d7d7;

    --ptb-footer: var(--space-xl);
    --plr-footer: var(--space-xl);
    --p-hero: var(--space-m) 0;

    --bg-icons-list: #fafafa;
    --p-icon-list: var(--space-m) 0;

    --fz-small: 12px;
    --fz-h1: 3rem;
    --fz-h2: 2rem;
    --fz-h3: 1.8rem;

    --bdrs-base: 3px;

    // buttons
    --bg-button: var(--c-green);
    --bd-button: 3px solid darken(var(--c-green), 5%);
    --c-button: #fff;
    --bdrs-button: 3px;
    --p-button: var(--space-xs) var(--space-l);

    --bg-button-alt: var(--c-white);
    --bd-button-alt: 2px solid var(--bg-top);
    --c-button-alt: var(--c-primary);

    --c-price: var(--c-green);
    --c-tax: #999;
    --c-light: #777;
    --bd-base: 1px solid var(--bdc-base);

    --bg-box: #fcfcfc;
    --bg-box: #fff;

    // tables
    --bg-thead-th: var(--bg-hero);
    --bg-copyright: #121212;
}

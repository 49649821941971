%container {
    width: min(var(--size-container), 98vw);
    margin-inline: auto;
}

%link {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    background-color: transparent;
    font-weight: bold;
    border-bottom: 2px solid var(--c-green);
    transition: border-width 300ms;

    &:hover {
        border-bottom-width: 4px;
    }
}

%contact-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
        span,
        strong {
            font-weight: 300;
            margin-right: 1rem;
        }
    }
}

%input {
    padding: 0.5em;
    border: 1px solid var(--c-primary);
    transition: all 1s;
    border-radius: var(--bdrs-base);

    &:focus,
    &:active {
        border-color: var(--c-green);
    }
}

%button-base {
    display: inline-block;
    position: relative;
    background: none;
    border: none;
    margin: 0;
    padding: 0;

    &[disabled] {
        background-color: var(--bg-button-disabled, #cacaca);
    }
}

%box-base {
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    background-color: var(--bg-box);
    border-radius: var(--bdrs-base);
    border: 1px solid var(--bdc-box, #ccc);
    padding: var(--space-m);
}

%underline {
    position: relative;
    margin-bottom: calc((var(--spacing) / 2));
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 50%;
        margin-left: -2rem;
        margin-top: 40px;
        bottom: -1.45rem;
        width: 4rem;
        height: 3px;
        background-color: var(--color-brand-primary);
        border-radius: 2px;
    }
}

/* loop info */

.mall-products-item {
    @extend %box-base;
}

.mall-products-item__price {
    color: var(--c-price);
    border-top: var(--bd-base);
    margin-top: var(--space-2xs);
    padding-top: var(--space-2xs);
}
.mall-products-item__name {
    font-weight: bold;
    margin-top: var(--space-m);
}

/* single product */
.mall-product__cart {
    @extend %box-base;
}

/*      */
.mall-signup {
    display: grid;
    gap: var(--space-m);
    @include grid-mixin();
}

.mall-signup__signin,
.mall-signup-form,
.mall-box {
    @extend %box-base;
    background-color: #fff;
}

.mall-products__products,
.categories-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: var(--space-s);
    text-align: center;
    margin-bottom: var(--space-xl);
}

.categories-list {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
.category-item {
    background-color: var(--bg-category-item, #fff);
    border: var(--bd-base);
    border-radius: 3px;

    img {
        margin-left: auto;
        margin-right: auto;
        max-width: 180px;
        margin-top: 10px;
    }
}

.mall-loader {
    display: none;
}

.mall-product {
    display: grid;
    margin-top: var(--space-l);
    margin-bottom: var(--space-l);
    @include grid-mixin();
}

.mall-product__current-price {
    font-size: var(--fz-h2);
    color: var(--c-price);
    font-weight: bold;
}
.mall-product__image {
    display: inline-block;
}
.mall-product__images {
    text-align: center;
}

.mall-product__tax-info {
    font-size: var(--fz-small);
    color: var(--c-tax);
}
.mall-product__add-to-cart-form {
    border: 1px solid #000;
    box-shadow: 0 0 10px hsl(var(--c-primary-hsl) / 0.3);
    border-radius: var(--bdrs-base);
    padding: var(--space-l);
    margin: var(--space-s) 0;
}

.mall-product__add-to-cart {
    margin: var(--space-s) 0;
}

.mall-property-group__name {
    font-weight: bold;
}

.mall-filter {
    margin-bottom: var(--space-s);
    margin-top: var(--space-3xs);

    input,
    select {
        width: 100%;
    }

    label {
        font-weight: normal;
    }
}

.mall-label--checkbox {
    input {
        display: block;
        opacity: 1;
    }

    &:before {
    }
}

.mall-wishlists-manager__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.counter {
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
    top: 0;
    right: 0;
    width: 20px;
    position: absolute;
    background: var(--c-green);
    border-radius: 50%;

    &.invisible {
        opacity: 0;
    }
}

.js-mall-remove-product {
    text-transform: uppercase;
    display: inline-block;
    font-size: 0.8em;
    margin-top: var(--space-2xs);
}

.mall-product__description,
.gray-bg {
    background-color: var(--bg-top);
    padding: var(--space-l) 0;
    margin: var(--space-l) 0;

    @include before-content;
    &:before {
        inset: 0;
        background-color: inherit;
        margin: 0 calc(50.4% - 50vw);
    }
}

/* reviews */

.mall-product-reviews {
    display: flex;
    gap: var(--space-m);
    flex-wrap: wrap;
}

.mall-product-reviews__add {
    margin-top: var(--space-s);
    padding-top: var(--space-s);
    border-top: 1px solid #000;
}

.mall-product-reviews__info {
    @extend %box-base;
    min-width: 200px;
}

.mall-product-reviews__info-name {
    font-weight: bold;
    font-size: var(--fz-h4, 1.4rem);
    margin-bottom: var(--space-s);
}

.mall-product-reviews__login-required {
    @extend %link;
}

.mall-reviews {
    flex-grow: 1;
    flex-basis: 60%;
}

.mall-star {
    height: 15px;
    width: 15px;
}

.mall-review {
    margin: var(--space-s) 0;

    & + .mall-review {
        border-top: 1px solid var(--bdc-base);
        padding-top: var(--space-s);
    }
}

.mall-review__pro-con-list {
    margin-bottom: 0;
}

.mall-star--active {
    stroke: var(--c-green);
    fill: #fff;
}

.mall-review__pro-con-list {
    list-style: none;
    margin-left: 0;
    padding: 0;
}

.mall-review__author {
    // border-top: var(--bd-base);
    padding-top: var(--space-3xs);
    color: var(--c-light);
}

.pro {
    color: var(--c-green);
}

.con {
    color: var(--c-red, red);
}

.mall-review__title {
    display: flex;
    align-items: center;

    .mall-review__rating:before {
        content: "";
        display: inline-block;
        height: 100%;
        width: 1px;
        flex-grow: 1;
        flex-basis: 1px;
        background-color: var(--c-light);
        margin-left: var(--space-xs);
        margin-right: var(--space-xs);
    }
}

/*FONTS*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;800&display=swap");

.screen__reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.has-medium-font-size {
    font-size: 1.3rem;
    line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--f-headings);
    font-weight: 800;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: var(--fz-h1);
}

h2 {
    font-size: var(--fz-h2);
}

h3 {
    font-size: var(--fz-h3);
}

address {
    font-style: normal;
}

p,
ul,
ol,
blockquote {
    line-height: 1.5;
    font-size: var(--fz-base);
}

.contact-list,
.mall-product-service-options {
    margin: 0;
    padding: 0;
    list-style: none;
}

.text-muted {
    opacity: 0.8;
    font-size: 0.9em;
}

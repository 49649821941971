@import "forms/fields";
@import "forms/buttons";

.contact-form {
    padding: calc(var(--spacing) / 3);
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
    margin-left: var(--spacing);
    margin-right: var(--spacing);
    position: relative;
    z-index: 2;
}

.inline-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 54em;
}

form {
    .group {
        margin-bottom: var(--size-padding);
    }
}

.alert {
    @extend %box-base;
    padding: 1rem;
    margin-bottom: calc(var(--spacing) / 3);

    &.alert-success {
        background-color: var(--color-brand-primary);
        color: var(--color-white);
    }

    p {
        margin: 0;
        text-align: center;
    }
}

.privacy-policy {
    display: flex;

    p {
        margin: 0;
        margin-left: 1rem;
        font-size: 0.8rem;
        margin-top: -5px;
    }
}

.wpcf7-not-valid-tip {
    font-weight: normal;
    text-transform: none;
    display: block;
    font-size: 0.8em;
    margin-top: 0.5em;
}

.mall-payment-method__entry,
.mall-shipping-method__entry {
    @extend %box-base;
    display: flex;
    background-color: var(--bg-top);
    gap: var(--space-m);
    flex-wrap: wrap;
}

.mall-payment-method__logo,
.mall-shipping-method__logo {
    min-width: 50px;
}

.mall-payment-method__entry--active,
.mall-shipping-method__entry--active {
    background-color: white;
}

.mall-payment-method__text,
.mall-shipping-method__text {
    gap: var(--space-m);
    > div {
        gap: var(--space-m);
        display: flex;
        flex-wrap: wrap;
    }
}

.mall-discount-applier {
    form {
        display: flex;
        align-items: center;
    }
}
.mall-cart-actions {
    text-align: right;
}

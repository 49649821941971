* {
    box-sizing: border-box;
}

*::selection {
    background: var(--bg-selection);
    color: var(--c-selection);
}

::-webkit-scrollbar {
    width: 0.6rem;
    // background-color: var(--color-brand-secondary);
    border-left: 1px solid #fcfcfc;
}

::-webkit-scrollbar-thumb {
    background: var(--bg-scrollbar);
    // height: 15rem;
}

html {
    min-height: 100%;
}

html,
body {
    scroll-padding-top: 65px; /* set to the height of your header */
    scrollbar-color: var(--bg-scrollbar) #fff;
    scrollbar-width: thin;
    scroll-padding: var(--scroll-padding, 100px);
}

hr {
    background-color: var(--bg-hr);
    border: none;
    height: 1px;
    display: block;
    flex-basis: 100%;
}

svg {
    fill: currentColor;
    max-width: 100%;
    height: auto;
}

body {
    font-family: var(--f-body);
    color: var(--c-body);
    padding: 0;
    margin: 0;
    font-size: var(--fz-body);
    display: flex;
    flex-direction: column;
    min-height: 100%;

    &.toggled {
        overflow: hidden;
    }

    @media screen and (max-width: 760px) {
        --fz-h1: 36px;
        --fz-h2: 32px;
        --fz-h3: 28px;
        --fz-base: 14px;
    }
}

a {
    text-decoration: none;
    transition: color 0.4s;
    color: currentColor;

    h1 &,
    h2 &,
    h3 &,
    h4 &,
    h5 &,
    h6 & {
        text-decoration: none;
    }
}

figure {
    margin: 0;
    padding: 0;
}

a:hover {
    text-decoration: none;
}

.flex {
    display: flex;
}

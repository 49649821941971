.hidden-svg {
    visibility: hidden;
    width: 0;
    height: 0;
}

.filled-icon {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
    fill: var(--fill-icon);
}

.stroked-icon {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
    fill: none;
    stroke: var(--stroke-icon);
    stroke-width: 1px;
}

img,
video,
iframe,
svg {
    max-width: 100%;
}
img {
    display: block;
}

.brand {
    svg {
        max-width: 256px;
    }
}

.icon-box {
    position: relative;
}

.icons-list {
    .flex {
        flex-wrap: wrap;
        gap: var(--space-s);
    }
    .icon-box {
        min-width: 200px;
        flex-wrap: nowrap;
    }
}

.mall-products-item__image img {
    margin-inline: auto;
}

.site-footer {
    background-color: var(--bg-footer);
    color: var(--c-footer);
    --fill-icon: #fff;
    padding-top: var(--space-xl);

    .flex {
        flex-wrap: wrap;
        gap: var(--space-m);
        margin-top: var(--space-m);
        margin-bottom: var(--space-m);

        > * {
            min-width: 200px;
            flex-grow: 1;
        }

        p {
            max-width: 50ch;
        }
    }

    a {
        @extend %link;
    }
}

.copyright {
    background-color: var(--bg-copyright);
    padding: var(--space-2xs) 0;

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: var(--space-m);
    }
}

.designer {
    text-align: center;
    padding-top: var(--p);
    margin-bottom: var(--s-padding);
    margin-top: var(--s-padding);

    svg {
        height: 10px;
        width: auto;
    }
}
